import Vue from 'vue'
import { LiffListConfigInterface, TableDataType } from '@/components/list/types/list'
import  backOrderTab  from '@/modules/base/config/backOrderTab.ts';
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string): LiffListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      searchFilterOrder: 0,
      pageTitle: 'module.backOrder',
      search: 'backOrder.search_keyword',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      liffFilter: {
        status: {
          type: "tab",
          // create: () => {
          //   // @ts-ignore
          //   const searchKeyword = vm.searchKeyword
          //   return !searchKeyword
          // },
          options: () => {
            return backOrderTab
          },
        },
      },
      listItem: () => import('@/modules/provider/views/backDetail/BackListComponents/orderCard.vue'),
    }

  }
}

export default new listConfig()
