export default [
    // 付款方式
    {
        text : "全部訂單",
        value : ""
    },
    {
        text : "新申請",
        value : "APPLY"
    },
    {
        text : "已同意",
        value : "SUCCESS"
    },
    {
        text : "已拒絕",
        value : "REJECT"
    },
]