<template>
  <v-container>
    <page-title title="退訂管理" />

    <liff-list :listKey="listKey">

    </liff-list>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import listMixin from "@/components/list/mixins/listMixin.js"
import listConfig from "./backList"

export default {
  mixins: [listMixin],
  components: {

  },
  data: () => ({
    listKey: 'liff-order-list',
    meta: {},
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
    status() {
      if(!this.listFilter) return null
      return this.listFilter.status
    },
    title() {
      if(!this.status) return ''
      return this.$t(`provider.status.${this.status}`)
    },
  },
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.providerApi.getBackOrders(this.providerId, params)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
